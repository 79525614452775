import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Callout from '../components/callout'

const QuotePage = () => {
  const formName = `quote`

  return (
    <Layout>
      <SEO title="Quote" />
      <main className="mb-12 mt-4 lg:mt-12 lg:mb-24 container max-w-xl">
        <form
          className="p-4 lg:p-8 mb-4 lg:mb-12 bg-tan shadow"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          method="post"
          name={formName}
        >
          <h1 className="text-xl lg:text-2xl text-center mb-4">Get A Quote</h1>
          <label className="block">
            <span className="font-medium text-sm">Name</span>
            <input className="form-input mt-1 block w-full" name="name" />
          </label>
          <label className="block mt-5">
            <span className="font-medium text-sm">Email</span>
            <input
              className="form-input mt-1 block w-full"
              name="email"
              type="email"
            />
          </label>
          <label className="block mt-5">
            <span className="font-medium text-sm">Phone Number</span>
            <input
              className="form-input mt-1 block w-full"
              name="telephone"
              type="tel"
            />
          </label>
          <label className="block mt-5">
            <span className="font-medium text-sm">
              Tell us about your artwork and project:
            </span>
            <textarea
              className="form-textarea mt-1 block w-full"
              name="message"
              rows="6"
            ></textarea>
          </label>
          <label
            aria-hidden="true"
            aria-label="spam protection. skip this field"
            className="hidden"
          >
            <span>Don&apos;t fill this out if you&apos;re human</span>
            <input name="bot-field" />
          </label>
          <div className="mt-10 text-right">
            <button className="btn btn-purple" type="submit">
              Send
            </button>
          </div>
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input name="form-name" type="hidden" value={formName} />
        </form>
        <div className="bg-gray-700 shadow p-4 lg:p-8 text-white">
          <h2
            aria-label="we are ready and waiting"
            className="text-white text-lg lg:text-xl mb-4"
          >
            We&apos;re ready &amp; waiting...
          </h2>
          <p className="text-sm">
            L.A. Art Exchange serves the Los Angeles and Santa Monica areas with
            exceptional custom framing services, superior design knowledge, and
            limitless art possibilities.
          </p>
          <ul
            className="mt-10 lg:grid gap-4"
            style={{ gridTemplateColumns: `repeat(2, 1fr)` }}
          >
            <li className="mb-4 lg:mb-0">
              <dl>
                <dt className="font-bold">Give us a ring.</dt>
                <dd>(310) 828-6866</dd>
              </dl>
            </li>
            <li className="mb-4 lg:mb-0">
              <dl>
                <dt className="font-bold">Hours:</dt>
                <dd>
                  M-F 9:30am - 6:00pm <br /> Sat 10am-5pm <br />
                  <span className="uppercase">Closed Sunday</span>
                </dd>
              </dl>
            </li>
            <li className="mb-4 lg:mb-0">
              <dl>
                <dt className="font-bold">Or come on in!</dt>
                <dd>
                  922 Santa Monica Blvd.
                  <br /> Santa Monica, CA 90401
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </main>
      <aside>
        <Callout />
      </aside>
    </Layout>
  )
}

export default QuotePage
